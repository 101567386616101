@import url(./material-override.less);

*:focus-visible {
  outline: 2px solid var(--signal) !important;
}

html {
  --dark-gray: #6e6e6e;
  --gray: #999999;
  --light-gray: #b5b5b5;
  --lighter-gray: #e5e5e5;

  --primary-background: #fff;
  --primary-textarea-border: #ddd;
  --offwhite: #ddd;

  body {
    padding: 0;
    margin: 0;
    overflow: hidden;

    .container-fluid {
      margin: 0;
      padding: 0;
      .row {
        margin: 0;
        padding: 0;
        [class^="col-"] {
          margin: 0;
          padding: 0;
        }
      }
    }

    .progress-bar {
      .mdc-linear-progress__bar,
      .mdc-linear-progress__bar-inner {
        animation-duration: 5s !important;
      }
    }

    .field-prefix {
      input[type="text"],
      input[type="number"],
      input[type="password"],
      input[type="tel"],
      input[type="time"],
      input[type="date"],
      textarea,
      .fake-input {
        border: 1px solid transparent !important;

        > * {
          border: none !important;
        }
      }
    }

    .field-prefix.warning {
      input[type="text"],
      input[type="number"],
      input[type="password"],
      input[type="tel"],
      input[type="time"],
      input[type="date"],
      mat-select,
      textarea:not(.no-error),
      .fake-input {
        border: 1px solid #f7c02b !important;

        &:focus {
          border: 1px solid transparent !important;
          outline-offset: -1px !important;
          outline-color: #f7c02b !important;
        }

        > * {
          border: none !important;
        }
      }
    }

    .field-prefix.error {
      input[type="text"],
      input[type="number"],
      input[type="password"],
      input[type="tel"],
      input[type="time"],
      input[type="date"],
      mat-select,
      textarea:not(.no-error),
      .fake-input {
        border: 1px solid #c9223b !important;

        &:focus {
          border: 1px solid transparent !important;
          outline-offset: -1px !important;
          outline-color: #c9223b !important;
        }

        > * {
          border: none !important;
        }
      }
    }

    input[type="text"],
    input[type="number"],
    input[type="password"],
    input[type="tel"],
    input[type="time"],
    input[type="date"],
    textarea,
    .fake-input {
      background: #f5f5f5;
      width: 100%;
      border: 1px solid transparent;
      border-radius: 4px;
      text-indent: 3px;
      padding: 5px;
      transition: filter 200ms;

      &:focus {
        outline: none;
        filter: brightness(1.03);
      }

      &:hover {
        filter: brightness(1.03);
      }
    }

    .fake-input {
      text-indent: 0;
      input[type="text"] {
        box-shadow: none;
        background: transparent;
        width: auto;
        border: 0;
        border-radius: 0;
        text-indent: 0;
        padding: 0;
        transition: none;
        text-align: center;
      }
    }

    select {
      width: 100%;
      border: 1px solid var(--lighter-gray);
      background: #f5f5f5;
      font-size: 13px;
      padding: 3px;
      color: #4a4a4a;
      cursor: pointer;

      &:focus {
        outline: none;
        filter: brightness(1.03);
      }

      &:hover {
        filter: brightness(1.03);
      }

      &:disabled {
        cursor: not-allowed;
      }
    }

    .ft-red {
      color: #f00;
    }

    .ft-green {
      color: #008000;
    }

    .ft-orange {
      color: #ffa500;
    }

    mat-select {
      min-width: 150px;
      padding: 5px 10px;
      border-radius: 4px;
      color: #4a4a4a;
      font-weight: 500;
      background: #f5f5f5;
      width: 100%;
      border: 1px solid transparent;
      user-select: none;

      span {
        font-size: 13px;
      }

      &:hover,
      &:focus {
        background: #efefef;
      }
    }

    .custom-scroll {
      position: relative;

      &::-webkit-scrollbar-thumb {
        background-color: #d4d4d4;
        border: 0.25rem solid transparent;
        border-radius: 100px;
        background-clip: padding-box;
      }

      &::-webkit-scrollbar {
        width: 1rem;
      }
    }

    .component {
      overflow: auto;
      max-height: fit-content;
      border-radius: 0.5rem;
      text-align: initial;
    }

    .icon-button {
      background-color: rgba(0, 0, 0, 0.05);
      border: 0;
      outline: 0;
      padding: 0.5rem;
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 2.125rem;
      cursor: pointer;
      transition: transform 0.2s;

      mat-icon {
        font-size: 1.125rem;
        width: 1.125rem;
        height: 1.125rem;
        color: #4f4f4f;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.075);
      }

      &.active {
        background-color: var(--primary);
        mat-icon {
          color: #fff;
        }

        &:hover {
          background-color: var(--primary);
        }
      }

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    .layout-mobile {
      input[type="text"],
      input[type="number"],
      input[type="password"],
      input[type="tel"],
      input[type="time"],
      input[type="date"],
      textarea,
      .fake-input {
        padding: 8px;
        font-size: 16px;
      }

      .fake-input {
        text-indent: 0;
        padding: 0;
        input[type="text"] {
          font-size: 16px;
        }
      }

      select {
        font-size: 16px;
        padding: 8px;
      }

      mat-select {
        padding: 8px;

        span {
          font-size: 16px;
        }
      }
    }
    .mdc-button:disabled {
      cursor: not-allowed !important;
    }
  }
}

@import "tooltip.less";
@import "snackbar.less";
@import "menu.less";
